import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { forwardRef } from "react";

const InputNumber = forwardRef(
  /**
   * @param {import("@chakra-ui/react").NumberInputProps & { onChange?: any}} props
   */
  function InputNumber({ name, onChange, ...otherProps }, ref) {
    return (
      <NumberInput
        variant="filled"
        {...otherProps}
        ref={ref}
        onChange={function (_, valueAsNumber) {
          onChange?.({
            target: {
              name,
              value: valueAsNumber,
            },
          });
        }}>
        <NumberInputField />

        <NumberInputStepper>
          <NumberIncrementStepper />

          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    );
  },
);

export default InputNumber;
