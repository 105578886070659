import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import resolveApiError from "../../../libraries/utils/reactHookForm/resolveApiError";

/**
 * @typedef {{
 * message: string | null,
 * setMessage: (message: string | null) => void,
 * caughtErrors: {[key:string]: string},
 * setCaughtErrors: (errors: {[key:string]: string}) => void,
 * uncaughtErrors: {[key:string]: string},
 * setUncaughtErrors: (uncaughtErrors: {[key:string]: string}) => void,
 * getApiError: (params: {name: string}) => void,
 * resolveApiError: (params: { response: any, form: import("react-hook-form").UseFormReturn<any> }) => void,
 * resetApiErrors(): void,
 * }} FormErrorContextValue
 **/

const ApiErrorContext = createContext(
  /** @type {FormErrorContextValue} */
  ({
    message: null,
    setMessage: () => {},
    caughtErrors: {},
    setCaughtErrors: () => {},
    uncaughtErrors: {},
    setUncaughtErrors: () => {},
    getApiError: () => {},
    resolveApiError: () => {},
    resetApiErrors: () => {},
  }),
);

/**
 * @param {{
 * children?: React.ReactNode,
 * render?: (props: FormErrorContextValue) => React.ReactNode,
 * }} param0
 * @returns
 */
export function ApiErrorProvider({ children, render }) {
  /** @type {[string | null, React.Dispatch<React.SetStateAction<string | null>>]} */
  const [message, setMessage] = useState(null);
  const [uncaughtErrors, setUncaughtErrors] = useState({});
  const [caughtErrors, setCaughtErrors] = useState({});

  const getApiError = useCallback(
    ({ name }) => {
      return caughtErrors[name] ?? uncaughtErrors[name] ?? null;
    },
    [caughtErrors, uncaughtErrors],
  );

  const _resolveApiError = useCallback(({ response, form }) => {
    const result = resolveApiError({
      response,
      form,
      setMessage,
      setCaughtErrors,
      setUncaughtErrors,
    });
    return result;
  }, []);

  const resetApiErrors = useCallback(() => {
    setMessage(null);
    setCaughtErrors({});
    setUncaughtErrors({});
  }, []);

  /** @type {FormErrorContextValue} */
  const contextValue = useMemo(() => {
    return {
      message,
      setMessage,
      uncaughtErrors,
      setUncaughtErrors,
      caughtErrors,
      setCaughtErrors,
      getApiError,
      resolveApiError: _resolveApiError,
      resetApiErrors,
    };
  }, [
    _resolveApiError,
    caughtErrors,
    getApiError,
    message,
    resetApiErrors,
    uncaughtErrors,
  ]);

  return (
    <ApiErrorContext.Provider value={contextValue}>
      {children}

      {render?.(contextValue)}
    </ApiErrorContext.Provider>
  );
}

/**
 * @template P
 * @param {React.FunctionComponent<P>} Component
 * @return {React.FunctionComponent<P>}
 */
export function withApiError(Component) {
  return ({ ...otherProps }) => {
    return (
      <ApiErrorProvider>
        <Component {...otherProps} />
      </ApiErrorProvider>
    );
  };
}

export function useApiError() {
  return useContext(ApiErrorContext);
}

export default ApiErrorContext;
